<template>
    <div class="content">
        <ul class="title">
            <li @click="chechTable('housesCheck')">选择房屋</li>
            <li @click="chechTable('unitCheck')">选择单元</li>
            <li @click="chechTable('storeyCheck')">选择楼幢</li>
        </ul>
        <component :is="currentView" class="all"></component>
    </div>

</template>

<script>
    export default {
        name: "newOrder",
        data(){
            return{
                currentView:"showAll"
            }
        },
        components:{
            housesCheck:()=>import('./housesCheck'),
            unitCheck:()=>import('./unitCheck'),
            storeyCheck:()=>import('./storeyCheck'),
            showAll:()=>import('./showAll'),
        },
        methods:{
            chechTable(componentTex){
                this.currentView=componentTex;
            }
        }
    }
</script>

<style scoped lang="scss">

.content{
    width: 1200px;
    margin: 0 auto;
    .title{
        li{
            cursor: pointer;
            width: 183px;
            color: #666;
            height: 39px;
            font-size: 14px;
            padding-left: 10px;
            line-height: 39px;
            background: #fff;
            margin-right: 10px;
            display: inline-block;
            list-style: none;
        }
    }
    .all{
        margin-top: 20px;
        min-height: 500px;
    }
}
</style>